import Axios from 'axios'

const instance = Axios.create({
    baseURL: "https://api.aitrix.online/api/",
})

export const MainPageApi = {
    sendOrderData(orderData) {
        return instance.post(`feedback/add/`, orderData)
    }
};
