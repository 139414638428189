import { MainPageApi } from "../Api/Api";
import { Toast } from "../utils/Toast";

const INITIALIZING = "INITIALIZING";
const SETISLOADING = "SETISLOADING";

export type AppDataInitialStateType = {
    initializing: boolean,
    isLoading: boolean,
    device: "Mobile" | "Desktop" | "Tablet" | "",
}
const initialState = {
    initializing: false,
    isLoading: false ,
    device: "",
}

const appReducer = (state = initialState, action:any) :AppDataInitialStateType =>{
    switch (action.type){
        case INITIALIZING:{
            console.log(`Инициализация - успешно: ${action.deviceType}`);
            return{ ...state, 
                initializing: true,
                device: action.deviceType
            }
        }
        case SETISLOADING:{
            return{ ...state, isLoading: action.answer}
        }
        default:{
            return state
        }
    }
}
type InitializingACType = { type: typeof INITIALIZING,deviceType: string}
export const initializingAC = (deviceType: string):InitializingACType =>({type: INITIALIZING, deviceType})

type SetIsLoadingACType = { type: typeof SETISLOADING,answer:boolean}
export const setIsLoadingAC = (answer: boolean) : SetIsLoadingACType =>({type: SETISLOADING, answer})

export const initializeTC = () => (dispatch:any)=>{
    if(window.innerWidth < 768){
        dispatch(initializingAC("Mobile"))
    }else{
        dispatch(initializingAC("Desktop"))
    }
}


type SetServiceTypeACType = { type: typeof SETSERVICETYPE, newServiceType: string}
export const setServiceTypeAC = (newServiceType: string):SetServiceTypeACType =>({type: SETSERVICETYPE, newServiceType})

type SetOrderDataACType = { type: typeof SETORDERDATA, orderData: Object}
export const setOrderDataAC = (orderData: Object):SetOrderDataACType =>({type: SETORDERDATA, orderData})

export const sendOrderDataTC = (orderData:object) => {
    return async(dispatch: any) =>{
        // dispatch(setIsLoadingAC(true))
        try{
            await MainPageApi.sendOrderData(orderData).then((response:any)=>{
                response.data.status === "success" 
                ? Toast.fire({ icon: "success", html: `Сообщение успешно отправлено` })
                : Toast.fire({ icon: "error", html: `${response.errors}` })
            })
            // dispatch(setIsLoadingAC(false))
        } catch(error){
            // dispatch(setIsLoadingAC(false))
            let response = {
                status: "error",
                errors: '',
            }
            for (let [key, value] of Object.entries(error.response.data)) {
                response.errors += `Поле: ${key}\n </br> Ошибка: ${value}\n `
            }
            Toast.fire({ icon: "error", html: `${response.errors}`, timer: 2500})
        }         
    }
}
export default appReducer