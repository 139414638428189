const INITIALIZING = "INITIALIZING";
const SETISLOADING = "SETISLOADING";

export type ProjectsDataInitialStateType = {
    initializing: boolean,
    isLoading: boolean,
    device: "Mobile" | "Desktop" | "Tablet" | "",
}
const initialState = {
    projectsList: [
        {id: "1", name: "Корпоративный сайт - Aitrix", type: "Корпоративный сайт", descr: "Корпоративный сайт компании, предоставляющей услуги проффесиональной разработки Программного обеспечения.", imgUrl:"http://locallhost:3000/static/media/projects/1.jpg",},
        {id: "2", name: "Landing Page - CryptoBit", type: "Посадочная страница", descr: "Посадочная страница для компании, предоставляющей услуги инвестиций для заработка на криптовалюте.", imgUrl:"",},
        {id: "3", name: "Корпоративный сайт - Сам Стирай", type: "Корпоративный сайт", descr: "Корпоративный сайт компании, предоставляющей услуги аренды и продажи стиральных машин Ландромат.", imgUrl:"",},
        {id: "4", name: "Корпоративный сайт - Nature Guide", type: "Корпоративный сайт", descr: "Корпоративный сайт компании, предоставляющей услуги туризма и сопроводжения по местам Хальмахеры.", imgUrl:"",},
        {id: "5", name: "Landing Page - Crypto Investor", type: "Посадочная страница", descr: "Посадочная страница для бизнесмена, занимающегося обучением в мире криптовалют, ориентирована на иностранную аудиторию.", imgUrl:"",},
        {id: "6", name: "Корпоративный сайт - Car Electric", type: "Корпоративный сайт", descr: "Корпоративный сайт для компании, предоставляющей услуги недорогой покупки электрических автомобилей из США.", imgUrl:"",},
        {id: "7", name: "Корпоративный сайт - Milatex", type: "Корпоративный сайт", descr: "Корпоративный сайт для компании, которая занимается продажей и изготовлением артопедических подушек на заказ.", imgUrl:"",},
        {id: "8", name: "Интернет магазин -  Biloatic", type: "Дизайн интернет магазина", descr: "Дизайн интернет магазина для Biolatic, компания занимается производством биотехнологической продукции.", imgUrl:"",},
        {id: "9", name: "Интернет магазин -  Рикша", type: "Интернет магазин", descr: "Интернет магазин для компании - Рикша. На сайте можно заказать самые популярные блюда Японской кухни и получить доставку на дом.", imgUrl:"",},
        {id: "10", name: "Интернет магазин - Duhi74.ru", type: "Интернет магазин", descr: "Интернет магазин для розничной и оптовой торговли парфюмерией, с возможностью доставки в любую точку России.", imgUrl:"",},
        {id: "11", name: "LandingPage - SecretUp", type: "Посадочная страница", descr: "Посадочная страница для Telegram проекта по запускам инфопродуктов. Простой, современный, информативный.", imgUrl:"",},
        {id: "12", name: "Онлайн игры - HotWin", type: "Сервис онлайн игр", descr: "Сервис многопользовательских онлайн игр на деньги, предназначенный для лиц старше 18 лет. Удобный, быстрый, надежный.", imgUrl:"%PUBLIC_URL%/../assets/projects/1.jpg",},
    ],
    isLoading: false ,
    device: "",
}

const projectsReducer = (state = initialState, action:any) :ProjectsDataInitialStateType =>{
    switch (action.type){
        case INITIALIZING:{
            console.log(`Инициализация - успешно: ${action.deviceType}`);
            return{ ...state, 
                initializing: true,
                device: action.deviceType
            }
        }
        case SETISLOADING:{
            return{ ...state, isLoading: action.answer}
        }
        default:{
            return state
        }
    }
}
type InitializingACType = { type: typeof INITIALIZING,deviceType: string}
export const initializingAC = (deviceType: string):InitializingACType =>({type: INITIALIZING, deviceType})

type SetIsLoadingACType = { type: typeof SETISLOADING,answer:boolean}
export const setIsLoadingAC = (answer: boolean) : SetIsLoadingACType =>({type: SETISLOADING, answer})

export const initializeTC = () => (dispatch:any)=>{
    if(window.innerWidth < 768){
        dispatch(initializingAC("Mobile"))
    }else{
        dispatch(initializingAC("Desktop"))
    }
}

export default projectsReducer