import React, { useEffect, useState } from 'react'
import s from './MainPage.module.scss'
import WOW from 'wow.js'
import { MobileMenu } from './MobileMenu/MobileMenu.tsx'
import { OrderForm } from './OrderForm/OrderForm.tsx'
import { ContactForm } from './ContactForm/ContactForm.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { PageLoader } from '../../Utils/PageLoader.tsx'
import { setIsLoadingAC } from '../../../redux/AppReducer.ts'
//IMAGES
import logo_img from '../../../assets/img/logo.png'
import offer__img from '../../../assets/img/offer_img.png'
import about_img1 from '../../../assets/img/about_img1.jpg'
import about_img2 from '../../../assets/img/about_img2.jpg'
import stages_img from '../../../assets/img/stages_img.jpg' 
import project_img_1 from '../../../assets/img/projects/4.jpg' 
import project_img_2 from '../../../assets/img/projects/5.jpg' 
import contact_img from '../../../assets/img/contact_img.jpg' 

export const MainPage = (props) => {
    const history = useLocation()    
    const isLoading = useSelector((state:any)=>state.appData.isLoading)
    // Модалка мобильного меню
    const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false)
    // Модалка формы заказа
    const [orderFormIsActive, setOrderFormIsActive] = useState(false)
    const [serviceType, setServiceType] = useState("Консультация")
    const dispatch = useDispatch()
    const openOrderForm = (serviceType)=>{
        setOrderFormIsActive(true)
        setServiceType(serviceType)
    }
    useEffect(() => {
        new WOW().init()
        dispatch(setIsLoadingAC(true))
    }, []);
    useEffect(() => {
        const hash = history.hash
        const el = hash && document.getElementById(hash.substr(1))
        if (el) {el.scrollIntoView({behavior: "smooth"})}
    }, [history.hash])
    useEffect(()=>{
        mobileMenuIsActive || orderFormIsActive 
        ? document.body.style.overflow = "hidden" 
        : document.body.style.removeProperty("overflow")
    },[mobileMenuIsActive,orderFormIsActive ])
    return (
        <>
            {isLoading ? <PageLoader loadingTime={2500}/>
                : <div className={`${s.wrapper}`}>
                    {mobileMenuIsActive && <MobileMenu setMobileMenuIsActive={setMobileMenuIsActive} />}
                    {orderFormIsActive && <OrderForm setOrderFormIsActive={setOrderFormIsActive} serviceType={serviceType} />}
                    <div className={s.sidebar}>
                        <div className={s.logo}>
                            <img src={logo_img} alt="logo_img" />
                        </div>
                        <div className={`${s.nav} animate__animated animate__fadeInLeft`}>
                            <Link className={s.nav__item} to="/#about"> <span>01/</span> О нас</Link>
                            <Link className={s.nav__item} to="/#services"> <span>02/</span> Услуги</Link>
                            <Link className={s.nav__item} to="/#works"> <span>03/</span> Работы</Link>
                            <Link className={s.nav__item} to="/#stages"> <span>04/</span> Этапы работ</Link>
                            <Link className={s.nav__item} to="/#contact"> <span>05/</span> Контакты</Link>
                        </div>
                        <div className={s.arrow_nav}>
                            <svg viewBox="0 0 490.656 490.656">
                                <path id="gal_1" d="M487.536,216.467c-4.16-4.16-10.923-4.16-15.083,0L245.339,443.581L18.203,216.467c-4.16-4.16-10.923-4.16-15.083,0
			            c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115l234.667-234.667
			            C491.696,227.389,491.696,220.627,487.536,216.467z">
                                </path>
                                <path id="gal_2" d="M487.536,24.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,251.581L18.203,24.467c-4.16-4.16-10.923-4.16-15.083,0
			            c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136L487.536,39.528
			            C491.696,35.368,491.696,28.605,487.536,24.445z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className={s.mobile_sidebar_btn} onClick={()=>setMobileMenuIsActive(true)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className={`${s.content}`}>
                        <section className={s.offer}>
                            <div className={s.mobile_logo}>
                                <img src={logo_img} alt="logo_img" />
                            </div>
                            <div className={s.offer__title}>
                                <div className={s.main}><p>Digital</p> <span>407</span></div>
                                <div className={s.subtitle}>Инновации в ваших руках</div>
                            </div>
                            <div className={`${s.offer__cos}`}>
                                <div className={s.offer__podTexts}>
                                    <span>Nextgen design</span>
                                    <span className={s.white}>Best solutions</span>
                                    <span>Lifetime warranty</span>
                                    <span className={s.white}>Professional</span>
                                    <span>Approach</span>
                                </div>
                                <img src={offer__img} alt="offer__img" className={s.offer__img} />
                            </div>
                            <div className={`${s.offer__text} animate__animated animate__fadeInLeft`}>Авторское маркетинговое и программное обеспечение <br /> с использованием современных технологий.</div>
                            <div className={s.offer__title_cos}>Innovation in your hands</div>
                        </section>
                        <section className={s.about} id="about">
                            <div className={`${s.section__titles} ${s.about__title}`}>
                                <div className={`${s.subtitle}  wow`}>About Us</div>
                                <h3 className={`${s.title}  wow`}><span>О нас//</span>  Мы - молодая веб-студия, состоящая из энтузиастов и профессионалов своего дела</h3>
                            </div>
                            <div className={s.about__list}>
                                <div className={`${s.about__item} ${s.first}`}>
                                    <div className={`${s.about__img} wow animate__animated animate__fadeInLeft`}>
                                        <img src={about_img1} alt="about_img1" className={s.back} />
                                        <img src={about_img1} alt="about_img1" className={s.front} />
                                    </div>
                                    <div className={`${s.about__text} wow animate__animated animate__fadeInUp`}>
                                        <div className={s.subtext}>Создаём будущее</div>
                                        Мы - специализируемся на авторском маркетинговом и программном обеспечении для среднего и
                                        крупного бизнеса, муниципальных, государственных структур.
                                        С 2014 года сотрудниками компании реализовано несколько десятков региональных и страновых проектов.
                                        Заказчики, пользователи и партнёры членов нашей команды, это - национальные и международные
                                        компании: IKEA, аэропорт ВНУКОВО, Coca-Сola,
                                        ДОДО Пицца, ВЦИОМ, ФК Открытие, представители малого бизнеса, политические деятели.

                                    </div>
                                </div>
                                <div className={`${s.about__item} ${s.second}`}>
                                    <div className={`${s.about__img} wow animate__animated animate__fadeInRight`}>
                                        <img src={about_img2} alt="about_img1" className={s.back} />
                                        <img src={about_img2} alt="about_img1" className={s.front} />
                                    </div>
                                    <div className={`${s.about__text} wow animate__animated animate__fadeInUp`}>
                                        Основная ценность нашей компании - создание качественного продукта и удовлетворение
                                        потребностей клиента. Поэтому перед началом разработки мы проводим маркетинговые исследования и помогаем
                                        клиенту определить стратегию развития продукта.
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={s.services} id="services">
                            <div className={`${s.services__intro} wow`}>Future</div>
                            <div className={`${s.section__titles}  ${s.services__titles}`}>
                                <div className={`${s.subtitle} wow `}><span>OUR</span> SERVICES</div>
                                <div className={`${s.title} wow `}><span>Услуги //</span> Предоставляемые нами услуги делятся на типы по их назначению, чтобы каждый смог заказать то, что ему нужно</div>
                            </div>
                            <div className={s.services__list}>
                                <div className={`${s.service} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.service__title}>Веб разработка</div>
                                    <div className={s.service__body}>
                                        <div className={s.service__assets}>
                                            <div className={s.service__subtitle}>// Web development</div>
                                            <span onClick={()=>openOrderForm("Веб разработка")}>Заказать</span>
                                        </div>
                                        <div className={s.service__info}>
                                            Комплексная разработка и реализация LandingPage, интернет магазины, Социальные
                                            Сети, корпоративные Web-приложения и иная разработка, необходимая для web. Мы даём гарантию
                                            современного внешнего вида проекта, а также стабильную работу серверов, файлов и баз данных с
                                            использованием современных технологий: React, Vue, FastAPI, Djangо и др.
                                        </div>
                                    </div>
                                </div>
                                <div className={`${s.service} ${s.service_right} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.service__title}>Мобильная разработка</div>
                                    <div className={s.service__body}>
                                        <div className={s.service__assets}>
                                            <div className={s.service__subtitle}>// Mobile development</div>
                                            <span onClick={()=>openOrderForm("Мобильная разработка")}>Заказать</span>
                                        </div>
                                        <div className={s.service__info}>
                                            Мы предлагаем уникальные мобильные приложения для крупного и среднего бизнеса, интегрированные с IT-системами потенциальных партнёров: iOS/Android, Кроссплатформа. 
                                            Комплексная разрабатка на Swift, Kotlin, ReactNative и др.
                                        </div>
                                    </div>
                                </div>
                                <div className={`${s.service} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.service__title}>Data Science</div>
                                    <div className={s.service__body}>
                                        <div className={s.service__assets}>
                                            <div className={s.service__subtitle}>// Data Science</div>
                                            <span onClick={()=>openOrderForm("Data Sciense")}>Заказать</span>
                                        </div>
                                        <div className={s.service__info}>
                                        Мы создаём и обучаем предиктивным моделям с помощью алгоритмов машинного
						                обучения и нейросетей, помогая бизнесу находить скрытые закономерности, прогнозировать развитие
						                событий и оптимизировать ключевые бизнес-процессы.
                                        </div>
                                    </div>
                                </div>
                                <div className={`${s.service} ${s.service_right} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.service__title}>Telegram боты</div>
                                    <div className={s.service__body}>
                                        <div className={s.service__assets}>
                                            <div className={s.service__subtitle}>// Telegram bots</div>
                                            <span onClick={()=>openOrderForm("Telegram bot")}>Заказать</span>
                                        </div>
                                        <div className={s.service__info}>
                                        Создаём ботов различной сложности для автоматизации любых бизнес процессов,
						                начиная с технической поддержки клиентов и заканчивая полным переносом вашего бизнеса в
						                Telegram. «Digital407 упрощает и улучшает бизнес, внедряя современные технологии.» 
                                        </div>
                                    </div>
                                </div>
                                <div className={`${s.service} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.service__title}>Маркетинг</div>
                                    <div className={s.service__body}>
                                        <div className={s.service__assets}>
                                            <div className={s.service__subtitle}>// Internet marketing</div>
                                            <span onClick={()=>openOrderForm("Интернет маркетинг")}>Заказать</span>
                                        </div>
                                        <div className={s.service__info}>
                                        Мы перенесём ваш бизнес и продукт на целевую аудиторию через новейшие
						                технологии и с уникальным брендбуком.
						                Благодаря анализу рынка, выявлению площадок с целевой аудиторией, разработки планинга на PR
						                кампанию, а также сотрудничеству с нашими стратегическими партнёрами, включая лидеров
						                общественного мнения, мы гарантируем исключение лишних затрат на рекламу продукта заказчика.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={s.works} id="works">
                            <div className={`${s.section__titles}  ${s.works__titles}`}>
                                <div className={`${s.subtitle} wow`}><span>Our</span> works</div>
                                <h3 className={`${s.title} wow`}><span>Работы //</span> Тут вы можете посмотреть на некоторые из наших проектов реализуемые без NDA январь - май 2022</h3>
                            </div>
                            <div className={s.works__list}>
                                <div className={`${s.work} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.work__img}>
                                        <img src={project_img_1} alt="work_img" className={s.img_back} />
                                        <img src={project_img_1} alt="work_img" className={s.img_front} />
                                    </div>
                                    <div className={s.work__info}>
                                        <div className={s.work__name}>Маркетплейс - Shopper Topper | Tech</div>
                                        <div className={s.work__type}>[Комплексная Web разработка]</div>
                                        <div className={s.work__description}>Маркетплейс предназаначенный для реализации санкционных товаров электронники в России, через зелёные коридоры.</div>
                                    </div>
                                </div>
                                <div className={`${s.work} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.work__img}>
                                        <img src={project_img_2} alt="work_img" className={s.img_back} />
                                        <img src={project_img_2} alt="work_img" className={s.img_front} />
                                    </div>
                                    <div className={s.work__info}>
                                        <div className={s.work__name}>Сервис грузоперевозок - SpaceCargo</div>
                                        <div className={s.work__type}>[Комплексная Web разработка]</div>
                                        <div className={s.work__description}>Сервис грузоперевозок предназаначеный для упрощения, отслеживания и наладки международных логистических цепочек.</div>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/projects"} className={`${s.works__btn} wow animate__animated animate__fadeInUp`}>Смотреть все проекты</Link>
                        </section>
                        <section className={s.stages} id="stages">
                            <div className={`${s.section__titles} ${s.stages__titles}`}>
                                <div className={`${s.subtitle} wow`}>Stages</div>
                                <h3 className={`${s.title} wow`}><span>Этапы работ //</span> Раздел, в котором поэтапно показано, как происходит создание вашего Web проекта</h3>
                            </div>
                            <div className={s.stages__content}>
                                <div className={s.stages__list}>
                                    <div className={`${s.stage} wow animate__animated animate__fadeInUp`}>
                                        <div className={s.stage__title}>Знакомство <br /> с нами</div>
                                        <div className={s.stage__text}>Свяжемся с вами, обсудим проект и выслушаем все ваши пожелания</div>
                                        <div className={s.stage__number}>1</div>
                                    </div>
                                    <div className={`${s.stage} wow animate__animated animate__fadeInUp`}>
                                        <div className={s.stage__title}>Подписание <br /> договора</div>
                                        <div className={s.stage__text}>Главный принцип: интеллектуальная собственность + NDA. Данный тип соглашений служит для предотвращения утечки любой конфиденциальной информации.</div>
                                        <div className={s.stage__number}>2</div>
                                    </div>
                                    <div className={`${s.stage} wow animate__animated animate__fadeInUp`}>
                                        <div className={s.stage__title}>Реализация <br /> проекта</div>
                                        <div className={s.stage__text}>Вы как заказчик или его представитель полностью посвящёны в процессы реализации проекта. </div>
                                        <div className={s.stage__number}>3</div>
                                    </div>
                                </div>
                                <div className={`${s.stages__info} wow animate__animated animate__fadeInRight`}>
                                    <div className={s.stages__intro}>Best solutions</div>
                                    <h4 className={s.title}>Создаем лучшие продукты</h4>
                                    <div className={s.text}>Вы можете принимать участие <span>во всех</span> творческих переговорах по реализации проекта команды DIGITAL 407 и легко сможете контролировать
                                        процесс,
                                        предлагать <span>свои идеи</span> в ходе реализации проекта, учитывая все этапы
                                        разработки. Наша команда <span >не использует</span> билдеров, конструкторов
                                        сайтов или шаблонов,
                                        а использует <span>только чистый код</span> и создаёт уникальный <span>авторский продукт.</span>
                                    </div>
                                    <div className={s.stages__img}>
                                        <div className={s.front} style={{ backgroundImage: `url(${stages_img})` }}></div>
                                        <div className={s.back} style={{ backgroundImage: `url(${stages_img})` }}></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={s.contact} id="contact">
                            <div className={`${s.contact__intro} wow`}>Hello Buenos días! Olá!</div>
                            <div className={`${s.section__titles}  ${s.contact__titles}`}>
                                <div className={`${s.subtitle} wow`}>Contact</div>
                                <h3 className={`${s.title} wow`}><span>Свяжитесь с нами //</span>Оставьте ваши контактные данные и опишите проект</h3>
                            </div>
                            <div className={s.contact__content}>
                                <ContactForm serviceType={serviceType}/>
                                <div className={`${s.contact__info} wow animate__animated animate__fadeInUp`}>
                                    <div className={s.contact__img}>
                                        <img src={contact_img} alt="about_img1" className={s.back} />
                                        <img src={contact_img} alt="about_img1" className={s.front} />
                                    </div>
                                    <div className={s.contact__links}>
                                        <a href="https://t.me/info_digital407" target={"__blank"}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -31 512 512">
                                                <path fill="#fff" d="M470.4354553,45.4225006L16.8273029,221.2490387c-18.253809,8.1874695-24.4278889,24.5854034-4.4127407,33.4840851l116.3710175,37.1726685l281.3674316-174.789505c15.3625488-10.9733887,31.0910339-8.0470886,17.5573425,4.023468L186.0532227,341.074646l-7.5913849,93.0762329c7.0313721,14.3716125,19.9055786,14.4378967,28.1172485,7.2952881l66.8582916-63.5891418l114.5050659,86.1867065c26.5942688,15.8265076,41.0652466,5.6130371,46.7870789-23.3935242L509.835083,83.1804428C517.6329956,47.474514,504.3345032,31.7425518,470.4354553,45.4225006z"></path>
                                            </svg>
                                            @info_digital407
                                        </a>
                                        <a href="mailto:info@digital407.com" target={"__blank"}>
                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -31 512 512" xmlSpace="preserve">
                                                <g><path fill="#fff" d="M485.211,363.906c0,10.637-2.992,20.498-7.785,29.174L324.225,221.67l151.54-132.584c5.895,9.355,9.446,20.344,9.446,32.219V363.906z M242.606,252.793l210.863-184.5c-8.653-4.737-18.397-7.642-28.908-7.642H60.651c-10.524,0-20.271,2.905-28.889,7.642L242.606,252.793z M301.393,241.631l-48.809,42.734c-2.855,2.487-6.41,3.729-9.978,3.729c-3.57,0-7.125-1.242-9.98-3.729l-48.82-42.736L28.667,415.23c9.299,5.834,20.197,9.329,31.983,9.329h363.911c11.784,0,22.687-3.495,31.983-9.329L301.393,241.631z M9.448,89.085C3.554,98.44,0,109.429,0,121.305v242.602c0,10.637,2.978,20.498,7.789,29.174l153.183-171.44L9.448,89.085z"></path></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                            </svg>info@digital407.ru
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </>
    )
}