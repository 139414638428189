import React, { useState } from 'react'
import { useDispatch} from 'react-redux'
import { sendOrderDataTC} from '../../../../redux/AppReducer.ts'
import { Toast } from '../../../../utils/Toast'
import s from './OrderForm.module.scss'
type PropsType={
    serviceType: string,
    setOrderFormIsActive: Function
}
export const OrderForm = (props:PropsType) => {
    const [isHideStyles, setIsHideStyles] = useState(false)
    const [policyIsActive, setPolicyIsActive] = useState(false)
    const closeMobileMenu = () => {
        setIsHideStyles(true)
        setTimeout(() => {
            props.setOrderFormIsActive(false)
            setIsHideStyles(false)
        }, 900)
    }
    const [userName,setUserName] = useState("")
    const [userPhone,setUserPhone] = useState("")
    const [userEmail,setUserEmail] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const dispatch = useDispatch()
    const setOrderData = ()=>{
        if(userName){
            if(userPhone){
                if(userEmail){
                    if(projectDescription){
                        if(policyIsActive){
                            dispatch(sendOrderDataTC({
                                company: "2",
                                serviceType: `${props.serviceType} Телефон: ${userPhone}`,
                                name: userName,
                                email: userEmail,
                                message: projectDescription
                            }))
                        }else{
                            Toast.fire({icon:"info", html:"Вы не приняли соглашение"})
                        }
                    }else{
                        Toast.fire({icon:"info", html:"Опишите свой проект"})
                    }
                }else{
                    Toast.fire({icon:"info", html:"Укажите Email"})
                }
            }else{
                Toast.fire({icon:"info", html:"Укажите Телефон"})
            }
        }else{
            Toast.fire({icon:"info", html:"Укажите имя"})
        }
    }
    return (
        <div className={s.wrapper} onClick={() => closeMobileMenu()}>
            <div className={`${s.close} ${isHideStyles && s.hide}`} onClick={() => closeMobileMenu()}>
                <svg className="exit_menu_mob" viewBox="0 0 612 512">
                    <path d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15 c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724 c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262 C276.412,478.129,266.908,468.625,255.15,468.625z"></path>
                    <path d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173 H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173 c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575 c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"></path>
                </svg>
            </div>
            <div className={`${s.blur_block} ${isHideStyles && s.hide}`}></div>
            <div className={`${s.cos_white_block} ${isHideStyles && s.hide}`} onClick={e => e.stopPropagation()}></div>
            <div className={`${s.form} ${isHideStyles && s.hide}`} onClick={e => e.stopPropagation()}>
                <h4 className={s.form__title}>Форма заказа</h4>
                <p className={s.form__subtitle}>
                    {props.serviceType==="Веб разработка" ? "Веб разработки" 
                        : props.serviceType==="Мобильная разработка" ? "Мобильной разработки" 
                            : props.serviceType==="Data Sciense" ? "Data Sciense услуги"
                                : props.serviceType==="Telegram bot" ? "Телеграм бота"
                                    : props.serviceType==="Интернет маркетинг" ? "Интернет маркетинга" : "Консультации"}
                </p>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='Как к вам обращаться?*'  value={userName} onChange={(e)=>setUserName(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='Куда нам позвонить?*'  value={userPhone} onChange={(e)=>setUserPhone(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='На какой email нам написать?*'  value={userEmail} onChange={(e)=>setUserEmail(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <textarea placeholder='Опишите что бы вы хотели создать' value={projectDescription} onChange={(e)=>setProjectDescription(e.currentTarget.value)}></textarea>
                </div>
                <div className={s.form__policy}>
                    <p>Поставьте крестик, чтобы дать свое согласие на обработку ваших персональных данных, в соответствии с ФЗ №152-ФЗ «О персональных данных» *</p>
                    <span className={`${policyIsActive && s.active}`}
                        onClick={() => policyIsActive ? setPolicyIsActive(false) : setPolicyIsActive(true)}></span>
                </div>
                <div className={s.form__btn} onClick={()=>setOrderData()}>Отправить заявку</div>
            </div>
        </div>
    )
}
