import React from 'react'
import { Link } from 'react-router-dom'
import s from './NotFoundPage.module.scss'

export const NotFoundPage = (props) => {
  return (
    <div className={s.wrapper}>
        <div className={s.title}>404</div>
        <p className={s.text}>Похоже такой страницы не существует</p>
        <Link to={"/"} className={s.btn_go}>Перейти на главную</Link>
    </div>
  )
}