import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoadingAC } from '../../redux/AppReducer.ts'
import s from './PageLoader.module.scss'

type PropsType={
    loadingTime: number,
}
export const PageLoader = (props:PropsType) => {
    const [hideLoading, setHideLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        setTimeout(() => {
            setHideLoading(true)
        }, props.loadingTime - 500 || 2000)
        setTimeout(() => {
            dispatch(setIsLoadingAC(false))
        }, props.loadingTime || 2500)
    },[])
    return (
        <div className={`${s.wrapper} ${hideLoading && s.hide} animate__animated animate__fadeIn`}>
            <h2>Digital 407</h2>
            <p>Инновации в ваших руках</p>
            <div className={s.line}><span></span></div>
        </div>
    )
}