import React, { useEffect } from 'react'
import s from './ProjectsPage.module.scss'
import WOW from 'wow.js'

import offer_img1 from '../../../assets/img/projects_offer_img.png'
import offer_img2 from '../../../assets/img/projects_offer_img2.png'

import { Link } from 'react-router-dom'

import project_img_1 from '../../../assets/img/projects/1.jpg'
import project_img_2 from '../../../assets/img/projects/2.jpg'
import project_img_3 from '../../../assets/img/projects/3.jpg'
import project_img_4 from '../../../assets/img/projects/4.jpg'
import project_img_5 from '../../../assets/img/projects/5.jpg'
import project_img_6 from '../../../assets/img/projects/6.jpg'
import project_img_7 from '../../../assets/img/projects/7.jpg'
import project_img_8 from '../../../assets/img/projects/8.jpg'
import project_img_9 from '../../../assets/img/projects/9.jpg'
import project_img_10 from '../../../assets/img/projects/10.jpg'
import project_img_11 from '../../../assets/img/projects/11.jpg'
import project_img_12 from '../../../assets/img/projects/12.jpg'

import { useDispatch, useSelector } from 'react-redux'
import { PageLoader } from '../../Utils/PageLoader.tsx'
import { setIsLoadingAC } from '../../../redux/AppReducer.ts'

export const ProjectsPage = (props) => {
    const isLoading = useSelector((state: any) => state.appData.isLoading)
    const projectsList = useSelector((state: any) => state.projectsData.projectsList)
    const dispatch = useDispatch()
    useEffect(() => {
        new WOW().init()
        // dispatch(setIsLoadingAC(true))
    }, [])
    if (isLoading) {
        return (
            <PageLoader loadingTime={2500} />
        )
    } else {
        return (
            <div className={s.wrapper}>
                <div className={s.offer}>
                    <Link to={"/"} className={s.offer__link}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                    <div className={s.offer__back}>
                        <span className={s.offer__textBack}>projects</span>
                        <span className={s.offer__textBack1}>projects</span>
                        <span className={s.offer__textBack2}>projects</span>
                        <span className={s.offer__textBack3}>projects</span>
                        <span className={s.offer__textBack4}>projects</span>
                        <span className={s.offer__textBack5}>projects</span>
                        <span className={s.offer__textBack6}>projects</span>
                        <span className={s.offer__textBack7}>projects</span>
                        <span className={s.offer__textBack8}>projects</span>
                        <span className={s.offer__textBack9}>projects</span>
                    </div>
                    <h2 className={s.offer__title}>Проекты</h2>
                    <p className={s.offer__subtitle}>Проекты представленные на этой странице досутупны лишь к частичному показу в связи с подписанным NDA</p>
                    <img src={offer_img1} className={`${s.offer__img} ${s.offer__img1}`} alt="projects_offer_img" />
                    <img src={offer_img2} className={`${s.offer__img} ${s.offer__img2}`} alt="projects_offer_img" />
                </div>
                <div className={s.projects__container}>
                    {/* <div className={s.projects__list}>
                        {projectsList.map(p => {
                            return (
                                <div className={`${s.project} animate__animated animate__fadeInUp`}>
                                    <div className={s.project__img}>
                                        <img src={p.imgUrl} alt="work_img" className={s.img_back} />
                                        <img src={p.imgUrl} alt="work_img" className={s.img_front} />
                                    </div>
                                    <div className={s.project__info}>
                                        <div className={s.project__name}>{p.name}</div>
                                        <div className={s.project__type}>[{p.type}]</div>
                                        <div className={s.project__description}>{p.descr}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}
                    <div className={s.projects__list}>
                        <div className={`${s.project} animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_1} alt="work_img" className={s.img_back} />
                                <img src={project_img_1} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Маркетплейс - Bloha.pro</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Маркетплейс предназаначенный для реализации санкционных секенд-хенд товаров в России, через зелёные коридоры.</div>
                            </div>
                        </div>
                        <div className={`${s.project} animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_2} alt="work_img" className={s.img_back} />
                                <img src={project_img_2} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Маркетплейс - Handm.shop</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Маркетплейс предназаначенный для реализации санкционных хендмейд товаров в России, через зелёные коридоры.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_3} alt="work_img" className={s.img_back} />
                                <img src={project_img_3} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Маркетплейс - Shopper Topper | Clothes</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Маркетплейс предназаначенный для реализации санкционных товаров брендовой одежды в России, через зелёные коридоры.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_4} alt="work_img" className={s.img_back} />
                                <img src={project_img_4} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Маркетплейс - Shopper Topper | Tech</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Маркетплейс предназаначенный для реализации санкционных товаров электронники в России, через зелёные коридоры.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_5} alt="work_img" className={s.img_back} />
                                <img src={project_img_5} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Сервис грузоперевозок - SpaceCargo</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Сервис грузоперевозок предназаначеный для упрощения, отслеживания и наладки международных логистических цепочек.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_6} alt="work_img" className={s.img_back} />
                                <img src={project_img_6} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Интернет Магазин - Duhi74</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Интернет магазин для оптовой и розничной продажи парфюмерии с возможностью доставки в любую точку России.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_7} alt="work_img" className={s.img_back} />
                                <img src={project_img_7} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Торговый бот - Aitrix</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Торговый бот тарифной системы с помощью которого клиенты могут выйти на пассивный доход 10%\Мес от суммы депозита.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_10} alt="work_img" className={s.img_back} />
                                <img src={project_img_10} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Сервис онлайн обучения - Skill.Study</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Сервсим онлайн обучения популярным IT профессиям,действует по программе подписки 1\3\12мес.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_9} alt="work_img" className={s.img_back} />
                                <img src={project_img_9} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Сервис многопользовательских онлайн игр - HotWin</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Сервис многопользовательских онлайн игр реализован для азартных игроков желающих испытать удачу в сети интернет.</div>
                            </div>
                        </div>
                        <div className={`${s.project} wow animate__animated animate__fadeInUp`}>
                            <div className={s.project__img}>
                                <img src={project_img_8} alt="work_img" className={s.img_back} />
                                <img src={project_img_8} alt="work_img" className={s.img_front} />
                            </div>
                            <div className={s.project__info}>
                                <div className={s.project__name}>Сервис онлайн обучения - SecretUp</div>
                                <div className={s.project__type}>[Комплексная Web разработка]</div>
                                <div className={s.project__description}>Сервис онлайн обучения, целью которого является научить зарабатывать на запусках инфопродуктов в телеграмм.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}