import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendOrderDataTC} from '../../../../redux/AppReducer.ts'
import { Toast } from '../../../../utils/Toast'
import s from './ContactForm.module.scss'
type PropsType={
    serviceType: string,
}
export const ContactForm = (props:PropsType) => {
    const [policyIsActive, setPolicyIsActive] = useState(false)
    const [userName,setUserName] = useState("")
    const [userPhone,setUserPhone] = useState("")
    const [userEmail,setUserEmail] = useState("")
    const [projectDescription,setProjectDescription] = useState("")
    const dispatch = useDispatch()
    const setOrderData = ()=>{
        if(userName){
            if(userPhone){
                if(userEmail){
                    if(projectDescription){
                        if(policyIsActive){
                            dispatch(sendOrderDataTC({
                                company: "2",
                                serviceType: `${props.serviceType} Телефон: ${userPhone}`,
                                name: userName,
                                email: userEmail,
                                message: projectDescription
                            }))
                            setTimeout(()=>{
                                setUserName("")
                                setUserPhone("")
                                setUserEmail("")
                                setProjectDescription("")
                                setPolicyIsActive(false)
                            },2000)
                        }else{
                            Toast.fire({icon:"info", html:"Вы не приняли соглашение"})
                        }
                    }else{
                        Toast.fire({icon:"info", html:"Опишите свой проект"})
                    }
                }else{
                    Toast.fire({icon:"info", html:"Укажите Email"})
                }
            }else{
                Toast.fire({icon:"info", html:"Укажите Телефон"})
            }
        }else{
            Toast.fire({icon:"info", html:"Укажите имя"})
        }
    }
    return (
        <div className={s.wrapper}>
            <div className={`${s.contact__form} wow animate__animated animate__fadeInUp`}>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='Как к вам обращаться?*'  value={userName} onChange={(e)=>setUserName(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='Куда нам позвонить?*' value={userPhone}  onChange={(e)=>setUserPhone(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <input type="text" placeholder='На какой email нам написать?*' value={userEmail}  onChange={(e)=>setUserEmail(e.currentTarget.value)}/>
                </div>
                <div className={s.inputBlock}>
                    <textarea placeholder='Опишите что бы вы хотели создать' value={projectDescription} onChange={(e)=>setProjectDescription(e.currentTarget.value)}></textarea>
                </div>
                <div className={s.contact__policy}>
                    <p>Поставьте крестик, чтобы дать свое согласие на обработку ваших персональных данных, в соответствии с ФЗ №152-ФЗ «О персональных данных» *</p>
                    <span className={`${policyIsActive && s.active}`}
                        onClick={() => policyIsActive ? setPolicyIsActive(false) : setPolicyIsActive(true)}></span>
                </div>
                <div className={s.contact__btn} onClick={()=>setOrderData()}>Отправить заявку</div>
            </div>
        </div>
    )
}