
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import appReducer, { AppDataInitialStateType }from './AppReducer.ts';
import projectsReducer, { ProjectsDataInitialStateType } from './ProjectsReducer.ts';

let rootReducer = combineReducers({
    appData: appReducer, 
    projectsData: projectsReducer,
});
export type AppStateType = {
    appData:  AppDataInitialStateType,
    projectsData: ProjectsDataInitialStateType,
};

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers( applyMiddleware(thunkMiddleware)))
//@ts-ignore
window.__store__ = store;
export default store;