import 'animate.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import '../assets/styles/main.scss'
import { initializeTC } from '../redux/AppReducer.ts';
import { MainPage } from './Pages/MainPage/MainPage.tsx';
import { NotFoundPage } from './Pages/NotFoundPage/NotFoundPage.tsx';
import { ProjectsPage } from './Pages/Projects/ProjectsPage.tsx';

export const App = ()=> {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(initializeTC())
  },[])
  return (
    <Routes>
      <Route path='/' element={<MainPage/>}/>
      <Route path='/projects' element={<ProjectsPage/>}/>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}