import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import s from './MobileMenu.module.scss'

export const MobileMenu = (props) => {
    const [hideMobileMenu, setHideMobileMenu] = useState(false)
    const navigate = useNavigate()
    const closeMobileMenu = () => {
        setHideMobileMenu(true)
        setTimeout(() => {
            props.setMobileMenuIsActive(false)
            setHideMobileMenu(false)
        }, 400)
    }
    const onLink = (hash)=>{
        setHideMobileMenu(true)
        setTimeout(() => {
            props.setMobileMenuIsActive(false)
            setHideMobileMenu(false)
        }, 400)
        navigate(`/#${hash}`)
    }
    return (
        <div className={s.wrapper} onClick={() => closeMobileMenu()}>
            <span className={`${s.close} ${hideMobileMenu && s.hide}`} onClick={() => closeMobileMenu()}>
                <svg className="exit_menu_mob" viewBox="0 0 612 512">
                    <path d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15
		                            c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724
		                            c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262
		                            C276.412,478.129,266.908,468.625,255.15,468.625z"></path>
                    <path d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173
		                            H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173
		                            c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575
		                            c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"></path>
                </svg>
            </span>
            <div className={`${s.blur_block} ${hideMobileMenu && s.hide}`}></div>
            <div className={`${s.cos_white_block} ${hideMobileMenu && s.hide}`} onClick={e => e.stopPropagation()}></div>
            <div className={`${s.mobile_menu_links} ${hideMobileMenu && s.hide} animate__animated animate__fadeInRight`}>
                <span onClick={()=>onLink("about")}>О нас</span>
                <span onClick={()=>onLink("services")}>Услуги</span>
                <span onClick={()=>onLink("works")}>Работы</span>
                <span onClick={()=>onLink("stages")}>Этапы работ</span>
                <span onClick={()=>onLink("contact")}>Контакты</span>
            </div>
        </div>
    )
}