import Swal from "sweetalert2"

export const Toast = Swal.mixin({
    position: 'center',
    showConfirmButton: false,
    timer: 1500,
    width: "auto",
    timerProgressBar: false,
    background: '#030b1f',
    color:"#fff",
    customClass: {
        htmlContainer: 'swal_modal_container',
        popup: "modal_popup",
        content: 'modal_content modal_content_error_bet',
        icon: 'modal_icon',
    },
})